/* stylelint-disable selector-class-pattern */
// You can directly set the default theme
// - `default` Default theme
// - `dark` Import the official dark less style file
// - `compact` Import the official compact less style file
@import '@delon/theme/theme-default.less';
@import '@fontsource/fira-mono';
@import '../node_modules/ng-zorro-antd/graph/style/entry.less';

// ==========The following is the custom theme variable area==========
// The theme paraments can be generated at https://ng-alain.github.io/ng-alain/
// @primary-color: #f50;

.alain-default__header-logo-expanded {
  padding: 8px;
}

.alain-default__header-logo-collapsed {
  padding: 8px;
}

.alain-default__nav-item {
  min-width: 0;
  padding: 8px;
}

.alain-default__header {
  padding-right: 0;
}

// Disable overflow scroll
html,
body {
  overscroll-behavior-y: none;
}

// ==========The following is the custom theme variable area==========
// The theme paraments can be generated at https://ng-alain.github.io/ng-alain/
// @primary-color: #f50;
@alain-default-aside-wd: 220px;
@alain-default-content-padding: 0xp;
@alain-default-header-hg: 48px;
// @alain-default-header-bg: white;
@alain-default-aside-collapsed-nav-fs: 16px;
@alain-default-aside-collapsed-nav-img-wh: 16px;

.page-header__title {
  font-weight: 300;
}

.page-header {
  height: 50px;
  padding: 8px;
}

// nz-list-item,
// li[nz-list-item],
// nz-list-header,
// ul[nz-list] {
//   &.active {
//     /* fade + border */
//     background-color: fade(@primary-color, 15%);
//     border: solid #1890ff 1px !important;
//   }

//   .prob-select-btn {
//     width: 24px;

//     /* border-width: 0; */
//     height: 24px;

//     &.predicted {
//       color: white !important;
//     }

//     &:not(.predicted) {
//       border-width: 1px !important;
//     }

//     &.rect {
//       width: unset !important;
//     }

//     &.active {
//       width: 34px;

//       /* box-shadow: 0 0 5px 5px white; */

//       /* border: solid white 2px; */
//       height: 34px;
//       margin-top: 5px;
//       margin-bottom: 5px;
//       font-weight: bold;
//       border: solid white 2px;
//     }

//     &:not(.active) {
//       margin-top: 10px !important;
//       margin-bottom: 10px !important;
//       border-width: 0;
//     }
//   }
// }

.highlight {
  // color: #389e0d !important;
  font-weight: bold;
  animation-name: highlight;
  animation-duration: 3s;
}

@keyframes highlight {
  from {
    background: fade(@primary-color, 25%);
  }

  to {
    background: transparent;
  }
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #40a9ff;
  border-color: orange;
}

nz-table.bg-transparent .ant-table{
  background: transparent;
}

// .ant-table {
//   width: 100%;
//   // overflow: scroll;
// }

// .area-color {
//   color: orange;
// }

// .field-color {
//   color: @primary-color;
// }

.ant-card {
  border: 1px solid rgb(200 200 200 / 60%);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 5%);
}

mark {
  padding: 0;
  font-weight: bold;
  background-color: transparent;
}

.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;

  &.rtl {
    direction: rtl;
  }
}